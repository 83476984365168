<i18n locale="th" lang="yaml" >
select.company_type.placeholder.default : "เลือกประเภทศูนย์บริการ"
</i18n>

<template>
	<a-select
		class="myselect-companies"
		:get-popup-container="getPopupContainer"
		:placeholder="displayCompanyTypePlaceholder"
		:value="value"
		:allow-clear="allowClear"
		:disabled="disabled"
		@change="handleChange">
		<a-select-option v-for="type in companyTypes" :key="type" :value="type">
			{{ type }}
		</a-select-option>
	</a-select>
</template>

<script>
import { Select } from 'ant-design-vue'
import { isStringEmpty } from '@utils/stringUtil'
import PopupMixin from '@mixins/PopupMixin.vue'
export default {
	components: {
		'a-select': Select,
		'a-select-option': Select.Option,
	},
	mixins: [PopupMixin],
	model: {
		prop: 'value',
		event: 'change',
	},
	props: {
		value: {
			type: null,
			default: undefined,
		},
		placeholder: {
			type: String,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		allowClear: {
			type: Boolean,
			default: false,
		},
		companyTypes: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		displayCompanyTypePlaceholder() {
			return isStringEmpty(this.placeholder) ? this.$t('select.company_type.placeholder.default') : this.placeholder
		},
	},
	methods: {
		handleChange(value) {
			this.$emit('change', value)
		},
	},
}
</script>

<style lang="less" scoped>
.myselect-companies {
	width: 200px;
}
</style>
